import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  success(event) {
    const [response, _status, _xhr] = event.detail

    document.getElementById(response.domId).dataset['notes-IconHasNotesValue'] = response.hasNotes
	  $.fancybox.close()
  }

  error(event) {
    const [_response, _status, xhr] = event.detail
	  const instance = $.fancybox.getInstance()

	  instance.current.$slide.trigger('onReset')
	  instance.setContent(instance.current, xhr.responseText)
  }
}
