import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  displayResponse(event) {
    const [_response, _status, xhr] = event.detail
	  const instance = $.fancybox.getInstance()

	  instance.current.$slide.trigger('onReset')
	  instance.setContent(instance.current, xhr.responseText)
  }
}
