import product_decision_icon from "~/images/jstree_icons/product_decision.png"
import building_type_decision_icon from "~/images/jstree_icons/building_type_decision.png"
import usage_decision_icon from "~/images/jstree_icons/usage_decision.png"
import profile_decision_icon from "~/images/jstree_icons/profile_decision.png"
import pitch_decision_icon from "~/images/jstree_icons/pitch_decision.png"
import environment_decision_icon from "~/images/jstree_icons/environment_decision.png"
import transportable_decision_icon from "~/images/jstree_icons/transportable_decision.png"
import country_decision_icon from "~/images/jstree_icons/country_decision.png"
import coil_age_decision_icon from "~/images/jstree_icons/coil_age_decision.png"
import supply_date_decision_icon from "~/images/jstree_icons/supply_date_decision.png"

import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['tree']
  static values = { source: String }

  connect() {
    useClickOutside(this, { element: this.treeTarget })

    $(this.treeTarget).jstree({
      plugins: ['contextmenu', 'types', 'dnd'],
      core: {
        multiple: false, // Dissallow selection of multiple items
        check_callback: true, // Allow changes to the tree strcture (dnd)
        force_text: true, // Ensure all nodes are plain text only
        dblclick_toggle: false, // Don't toggle a node on doubleclick
        themes: {
          stripes: true // Stripe the background of the tree
        },
        data: {
          url: this.sourceValue
        }
      },
      dnd: {
        copy: false,
        touch: false,
        inside_pos: 'last' // When dropping in a parent, add to bottom of list
      },
      types: {
        product_decision: { icon: product_decision_icon },
        building_type_decision: { icon: building_type_decision_icon },
        usage_decision: { icon: usage_decision_icon },
        profile_decision: { icon: profile_decision_icon },
        pitch_decision: { icon: pitch_decision_icon },
        environment_decision: { icon: environment_decision_icon },
        transportable_decision: { icon: transportable_decision_icon },
        country_decision: { icon: country_decision_icon },
        coil_age_decision: { icon: coil_age_decision_icon },
        supply_date_decision: { icon: supply_date_decision_icon }
      },
      contextmenu: {
        items(node) {
          const tree = $.jstree.reference(node)

          return {
            edit: {
              label: 'Edit',
              icon: 'fa-sharp fa-solid fa-pen-to-square',
              action() {
                $.get(`${node.data.src}/edit`).done((data) => {
                  $.fancybox.open(data, { modal: true })
                })
              }
            },
            delete: {
              label: 'Delete',
              icon: 'fa-sharp fa-solid fa-xmark',
              action() {
                if (confirm('Are you sure you want to delete this decision and its descendants?')) {
                  tree.delete_node(node)
                }
              }
            }
          }
        }
      }
    })

    $(this.treeTarget).on('move_node.jstree', this.moveNode.bind(this))
    $(this.treeTarget).on('delete_node.jstree', this.deleteNode.bind(this))
    $(this.treeTarget).on('create_node.jstree', this.selectNode.bind(this))
  }

  newDecision(event) {
    $.fancybox.open(event.currentTarget, {
      modal: true,
      type: 'ajax',
      ajax: {
        settings: {
          data: {
            parent_id: () => {
              const parent_node = $.jstree.reference(this.treeTarget).get_selected(true)[0]
              return parent_node ? parent_node.id : ''
            }
          }
        }
      }
    })
  }

  moveNode(_event, data) {
    const tree = data.instance
    const node = data.node
    const parent_node = tree.get_node(data.parent)

    $.ajax({ url: `${node.data.src}/move`, method: 'PATCH', data: {
      sibling_ids: parent_node.children,
      parent_id: data.parent != "#" ? data.parent : null,
    }}).fail(() => tree.refresh(true))
  }

  deleteNode(_event, data) {
    const tree = data.instance
    const node = data.node

    $.ajax({ url: node.data.src, method: 'DELETE' }).fail(() => tree.refresh(true))
  }

  selectNode(_event, data) {
    const tree = data.instance
    const node = data.node

    tree.select_node(node, true)
  }

  clickOutside() {
    $.jstree.reference(this.treeTarget).deselect_all()
  }

  ajaxSuccess(event) {
    const [response, _status, _xhr] = event.detail
    const tree = $.jstree.reference(this.treeTarget)

    if (response.method === 'create') {
      const parent_node = response.parent_id ? tree.get_node(response.parent_id) : null

      tree.create_node(parent_node, response.decision)
    } else if (response.method === 'update') {
      const node = tree.get_node(response.decision.id)

      // Update the name and disabled state of the node
      node.text = response.decision.text
      node.li_attr = response.decision.li_attr

      tree.redraw(true)
      tree.select_node(node, true)
    }

    $.fancybox.close()
  }

  ajaxError(event) {
    const [_response, _status, xhr] = event.detail
	  const instance = $.fancybox.getInstance()

	  instance.current.$slide.trigger('onReset')
	  instance.setContent(instance.current, xhr.responseText)
  }
}
