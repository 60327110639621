import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['distance']
  static values = { selected: Boolean }

  setSelectedValue(event) {
    this.selectedValue = event.currentTarget.checked && event.currentTarget.value
  }

  selectedValueChanged() {
    $(this.distanceTarget).toggle(this.selectedValue)
  }
}
