import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    hasNotes: Boolean
  }

  hasNotesValueChanged() {
    if (this.hasNotesValue) {
      this.element.classList.add('has_notes')
    } else {
      this.element.classList.remove('has_notes')
    }
  }
}
