import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    const controller = this

    $(this.element).tableDnD({
      dragHandle : '.handle',
      onDrop() {
        $.ajax({
          method: 'PATCH',
          data: $(this.element).tableDnDSerialize(),
          url: controller.urlValue
        })
      }
    })
  }
}
