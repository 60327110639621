import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { child: String }

	connect() {
    if (!this.element.disabled) {
      $(this.childValue).chained(this.element)
    }
  }
}
