import '~/libraries/jquery'

// jQuery Plugins
import 'tablednd'
import 'jquery-chained'
import '~/libraries/jquery-ui'
import '~/libraries/fancybox'
import '~/libraries/jstree'

import Rails from '@rails/ujs'
Rails.start()

import '~/controllers'
