import CodeMirror from 'codemirror'

import 'codemirror/mode/xml/xml'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/mode/css/css'
import 'codemirror/mode/htmlmixed/htmlmixed'

import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/paraiso-dark.css'

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
	static targets = ['template', 'header', 'footer']

	connect() {
		const options = { mode: 'text/html', theme: 'paraiso-dark', lineWrapping: true }

		CodeMirror.fromTextArea(this.templateTarget, options)
		CodeMirror.fromTextArea(this.headerTarget, options)
		CodeMirror.fromTextArea(this.footerTarget, options)
	}
}
