import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    enquiry: Boolean, finalised: Boolean,
    subtypeRequired: Boolean, subtypeDetailsRequired: Boolean,
    supportsTransportableBuildings: Boolean
  }

  static targets = [
    'buildingTypeInput',
    'buildingSubtypeWrapper', 'buildingSubtypeInput',
    'buildingSubtypeDetailsWrapper', 'buildingSubtypeDetailsInput',
    'countryInput', 'buildingTransportableWrapper',
    'supplyDateWrapper', 'requiredLabel'
  ]

  connect() {
    $(this.buildingTypeInputTarget).on('change', this.setSubtypeRequiredValue.bind(this))
    $(this.buildingSubtypeInputTarget).on('change', this.setSubtypeDetailsRequiredValue.bind(this))
    $(this.countryInputTarget).on('change', this.setSupportsTransportableBuildingsValue.bind(this))
  }

  setSubtypeRequiredValue() {
    this.subtypeRequiredValue = this.buildingTypeInputTarget
      .options[this.buildingTypeInputTarget.selectedIndex].dataset.subtypeRequired === 'true'
  }

  setSubtypeDetailsRequiredValue() {
    this.subtypeDetailsRequiredValue = this.buildingSubtypeInputTarget
      .options[this.buildingSubtypeInputTarget.selectedIndex].dataset.detailsRequired === 'true'
  }

  setSupportsTransportableBuildingsValue() {
    this.supportsTransportableBuildingsValue = this.countryInputTarget
      .options[this.countryInputTarget.selectedIndex].dataset.supportsTransportableBuildings === 'true'
  }

  setEnquiryValue(event) {
    this.enquiryValue = event.currentTarget.checked && event.currentTarget.value
  }

  subtypeRequiredValueChanged() {
    $(this.buildingSubtypeWrapperTarget).toggle(this.subtypeRequiredValue)
  }

  subtypeDetailsRequiredValueChanged() {
    if (this.subtypeDetailsRequiredValue) {
      $(this.buildingSubtypeDetailsWrapperTarget).show()
    } else {
      $(this.buildingSubtypeDetailsWrapperTarget).hide()
      this.buildingSubtypeDetailsInputTarget.value = ''
    }
  }

  supportsTransportableBuildingsValueChanged() {
    $(this.buildingTransportableWrapperTarget).toggle(this.supportsTransportableBuildingsValue)
  }

  enquiryValueChanged() {
    $('abbr', this.requiredLabelTargets).toggle(!this.enquiryValue)
    $(this.supplyDateWrapperTarget).toggle(!this.enquiryValue)
    $('input, select', this.supplyDateWrapperTarget).prop('disabled', this.enquiryValue)
  }
}
