import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['rollformers']
  static values = {
    administrator: Boolean
  }

  setAdministratorValue(event) {
    this.administratorValue = event.currentTarget.checked && event.currentTarget.value
  }

  administratorValueChanged() {
    $(this.rollformersTarget).toggle(!this.administratorValue)
  }
}
