import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
	static values = {
    requiresPitch: Boolean, requiresProfile: Boolean
	}

	static targets = ['usageInput', 'pitch', 'profileInput', 'otherProfile']

	connect() {
		$(this.usageInputTarget).on('change', this.setRequiresPitchValue.bind(this))
	}

	setRequiresPitchValue() {
		this.requiresPitchValue = this.usageInputTarget
			.options[this.usageInputTarget.selectedIndex].dataset.requiresPitch === 'true'
	}

	setRequiresProfileValue() {
		this.requiresProfileValue = this.profileInputTarget.value === ''
	}

	requiresPitchValueChanged() {
		$(this.pitchTarget).toggle(this.requiresPitchValue)
	}

	requiresProfileValueChanged() {
		$(this.otherProfileTarget).toggle(this.requiresProfileValue)
	}
}
