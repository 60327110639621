import loadGoogleMapsApi from 'load-google-maps-api'

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['googleFormattedAddress', 'address', 'country', 'map', 'automaticallyFindAddress']
  static values = { latitude: Number, longitude: Number, supportsAddressVerification: Boolean, finalised: Boolean }

  connect() {
    $(this.countryTarget).on('change', this.setSupportsAddressVerificationValue.bind(this))

    loadGoogleMapsApi({
      key: 'AIzaSyAN0rAC2pJSS0htz9w0e1E6dp25bD1YVeE', libraries: ['places']
    }).then((googleMaps) => {
      this.googleMaps = googleMaps
      this.initialiseForm()
    }).catch(function(error) {
      console.error(error)
    })
  }

  initialiseForm() {
    this.geocoder = new this.googleMaps.Geocoder()

    if (this.supportsAddressVerificationValue) {
      if (!this.googleFormattedAddressTarget.value && this.addressTarget.value) {
        this.showManualAddress()
      } else {
        if (this.latitudeValue && this.longitudeValue) {
          const latLng = new this.googleMaps.LatLng(this.latitudeValue, this.longitudeValue)

          this.dropMarker(latLng, this.addressTarget.value)
        } else {
          this.focusOnSelectedCountry()
        }
      }
    } else {
      $(this.automaticallyFindAddressTarget).hide()
      this.showManualAddress()
    }

    $(this.countryTarget).on('change', this.changeCountry.bind(this))

    const autocompleteService = new this.googleMaps.places.AutocompleteService()
    const autocompleteSessionToken = new this.googleMaps.places.AutocompleteSessionToken()

    $(this.googleFormattedAddressTarget).autocomplete({
      source: (request, response) => {
        autocompleteService.getPlacePredictions(
          {
            input: request.term,
            componentRestrictions: {
              country: this.countryTarget.options[this.countryTarget.selectedIndex].dataset.alpha2
            },
            types: ['street_address', 'premise'],
            sessionToken: autocompleteSessionToken
          },
          (predictions, status) => {
            if (status === this.googleMaps.places.PlacesServiceStatus.OK) {
              response(
                $.map(predictions, (prediction) => {
                  return {
                    value: prediction.description,
                    prediction: prediction
                  }
                })
              )
            } else {
              response([])
            }
          }
        )
      },
      select: (_event, ui) => {
        const placesService = new this.googleMaps.places.PlacesService(this.map)

        placesService.getDetails(
          {
            placeId: ui.item.prediction.place_id,
            fields: ['geometry.location', 'formatted_address'],
            sessionToken: autocompleteSessionToken
          },
          (result, status) => {
            if (status === this.googleMaps.places.PlacesServiceStatus.OK) {
              this.googleFormattedAddressTarget.value = result.formatted_address
              this.dropMarker(result.geometry.location, result.formatted_address)
            }
          }
        )
        return false
      },
      minLength: 4
    })
  }

  setSupportsAddressVerificationValue() {
    this.supportsAddressVerificationValue = this.countryTarget.options[this.countryTarget.selectedIndex]
      .dataset.supportsAddressVerification === 'true'
  }

  changeCountry() {
    this.googleFormattedAddressTarget.value = ''
    this.addressTarget.value = ''
    this.clearMarker()

    if (this.supportsAddressVerificationValue) {
      $(this.automaticallyFindAddressTarget).show()
      if (!this.googleFormattedAddressTarget.disabled) {
        this.focusOnSelectedCountry()
      }
    } else {
      $(this.automaticallyFindAddressTarget).hide()
      this.showManualAddress()
    }
  }

  initialiseMap(center) {
    this.map = new this.googleMaps.Map(this.mapTarget, {
      zoom: 0,
      mapTypeId: this.googleMaps.MapTypeId.ROADMAP,
      center: center
    })
  }

  showManualAddress() {
    if (!this.finalisedValue) {
      $(this.addressTarget).prop('disabled', false)
      $(this.googleFormattedAddressTarget).prop('disabled', true)
    }

    $(this.addressTarget).closest('div.input').show()
    $(this.googleFormattedAddressTarget).closest('div.input').hide()
  }

  automaticallyFindAddress() {
    if (!this.finalisedValue) {
      $(this.googleFormattedAddressTarget).prop('disabled', false)
      $(this.addressTarget).prop('disabled', true)
    }

    $(this.googleFormattedAddressTarget).closest('div.input').show()
    $(this.addressTarget).closest('div.input').hide()

    if (!this.marker) { this.focusOnSelectedCountry() }
  }

  focusOnSelectedCountry() {
    const selectedCountry = this.countryTarget.options[this.countryTarget.selectedIndex].text

    this.geocoder.geocode({ address: selectedCountry }, (results, status) => {
      if (status === this.googleMaps.GeocoderStatus.OK) {
        if (!this.map) { this.initialiseMap(results[0].geometry.location) }
        this.map.setCenter(results[0].geometry.location)
        this.map.fitBounds(results[0].geometry.bounds)
      } else {
        console.error(`Geocode was not successful for the following reason: ${status}`)
      }
    })
  }

  dropMarker(location, title) {
    if (!this.map) { this.initialiseMap(location) }

    this.map.setCenter(location)
    this.map.setZoom(16)

    this.marker = new this.googleMaps.Marker({
      position: location,
      map: this.map,
      title: title,
      animation: this.googleMaps.Animation.DROP
    })
  }

  clearMarker() {
    if (this.marker) {
      this.marker.setMap(null)
      delete this.marker
    }
  }
}
