import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  open(event) {
    $.fancybox.open(event.currentTarget, {
      modal: event.params.modal,
      type: 'ajax'
    })
  }

  ajaxSuccess(event) { this.dispatch('ajaxSuccess', { detail: event.detail }) }

  ajaxError(event) { this.dispatch('ajaxError', { detail: event.detail }) }

  close() {
    $.fancybox.close()
  }
}
